import React, { useState, useEffect } from "react"
import { Link, navigate } from 'gatsby'

import Illustrations from '../components/Illustrations/Illustrations'

import useAuth from '../hooks/useAuth'

export default ( { location }) => {

	const { changePassword } = useAuth();

	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const [ passwordMatchError, setPasswordMatchError ] = useState(false);
	const [ token, setToken ] = useState();
	const [ error, setError ] = useState( false );
	const [ showForm, setShowForm ] = useState( true )

	useEffect(() => {
		if ( location.search ){
			let searchQuery = location.search.replace(/[=?]/g, " ")
			//sorting out the search query
			searchQuery = searchQuery.split(" ").splice(1)
			if( searchQuery[0] ==='code' ){
				setToken( searchQuery[1] )
			}
		}
	}, [ location.search ])

	const handleSubmit = async ( event ) => {
		event.preventDefault()

		if ( password !== confirmPassword ) {
			setPasswordMatchError( true )
			return;
		} else {
			setPasswordMatchError( false )
		}

		const data = {
			code: token,
			password,
			passwordConfirmation: confirmPassword
		}

		try {
			await changePassword( data );
			navigate( '/account' )
			setShowForm( false )
		} catch( e ) {
			console.log("Error occurred during authentication")
			setError( true )
		}
	}

	return (
		<div className="wrapper">
			<div className="form-page">
				<div className="form-page__header">
					<h1 className="form-page__heading">Reset your password</h1>
				</div>

				{ showForm ? (
					<form  className="form" onSubmit={ handleSubmit }>
						<div className="form__input-container">
							<label className="form__label" htmlFor="password">New Password</label>
							<input className="form__input" type="password" name="password" id="password" value={ password } placeholder="New password" onChange={ e => { setPassword( e.target.value ) } } required/>
						</div>
						<div className={`form__input-container ${ passwordMatchError ? 'error' : '' }`}>
							<label className="form__label" htmlFor="confirmPassword">Confirm New Password</label>
							<input className="form__input" type="password" name="confirmPassword" id="confirmPassword" value={ confirmPassword } placeholder="Confirm new password" onChange={ e => { setConfirmPassword( e.target.value ) } } required/>
							<span className="form__input-error">Password confirmation does not match password!</span>
						</div>

						{ error &&
							<div className="form__error-container">
								<span className="form__error-message">Sorry, this link has expired.</span>
								<span className="form__error-message"><Link to="/forgotten-password">Resend email</Link></span>
							</div>
						}

						<div className="form__button-container">
							<button className="form__submit button button--primary button--full-width" type="submit">Change Password</button>
						</div>
					</form>
				) : (
					<p className="form-page__success">
						Your password has been reset
						<Illustrations class="form-page__success-image" illustration={ 'Family' } />
					</p>
				) }
			</div>
		</div>
	)
}